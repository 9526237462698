.filterCard {
  margin-top: 10px;
  margin-bottom: 10px;
  // box-shadow: 0 0 0.6rem #ddddddb3;
  border-radius: 0.5rem;
}

.cardHeader {
  margin-block-end: 1rem;
  border-bottom: 0.5px solid rgba(99, 85, 85, 0.295);
  width: fit-content;
  padding-left: 1rem;
  padding-block-end: 0.8rem;
  width: 100%;
}

.mStart05 {
  margin-inline-start: 0.5rem;
}

.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 0.5rem;
  height: 90%;
  overflow-y: auto;
  justify-content: space-between;
  width: 100%;
}