.container {
  display: flex;
  flex-direction: column;

  background-color: rgb(43, 54, 58);
  border-radius: 8px;
  min-height: 100%;

  // overflow-y: auto;
  justify-content: space-between;
}

[data-theme='light'] .container {
  background-color: white;
}

.children {
  overflow-y: scroll;
  padding: 0.5rem;
  overflow-x: hidden;
  height: calc(75vh - 94px);

}

@media (min-height: 881px) {
  .children {
    overflow-y: scroll;
    padding: 0.5rem;
    overflow-x: hidden;
    height: calc(81vh - 94px);
  }
}



.title {
  font-size: 20px;
  padding-right: 16px;
  padding-left: 16px;
  font-weight: 600;
}

.divider {
  margin: 0;
  margin-bottom: 10px;
}

.formFooter {
  display: flex;
  padding: 0.5rem;
  gap: 0.7rem;
  justify-content: flex-end;
}