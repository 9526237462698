.formItem {
  margin-bottom: 1rem;
}

.formItemLabel {
  display: block;
  margin-bottom: 0.5rem;
  // font-weight: bold;
}

.star {
  padding: 0.3rem;
  font-weight: bold;
  color: #FB5D48;

}