.tree-container {
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.tree {
  width: 100%;
  height: auto;
  text-align: center;
}

.tree ul {
  padding-top: 20px;
  position: relative;
  transition: 0.5s;
}

.tree li {
  display: inline-table;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 10px;
  transition: 0.5s;
}

/*We used ::before and ::after to draw the connectors*/

.tree li::before,
.tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 2px solid #ccc;
  width: 51%;
  height: 10px;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 2px solid #ccc;
}

.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

.tree li:only-child {
  padding-top: 0;
}

.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

.tree li:last-child::before {
  border-right: 2px solid #ccc;
  border-radius: 0px 5px 0px 0px;
}

.tree li:first-child::after {
  border-radius: 5px 0px 0px 0px;
}

.tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #ccc;
  width: 0;
  height: 20px;
}

.tree a {
  border: 2px solid #ccc;
  z-index: 10;
  padding: 10px;
  display: inline-grid;
  border-radius: 5px;
  text-decoration: none;
  transition: 0.5s;
}

.tree a h1 {
  width: 150px;
  font-size: 16px;
  height: 50px;
  margin-bottom: 10px;
}

.tree a span {
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #666;
  padding: 8px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}

/* Hover Section */

[data-theme='light'] .tree li a:hover,
.tree li a:hover+ul li a {
  background: #1a2d3f65;
  color: black;
  border: 2px solid #3665b7c2;
}

[data-theme='dark'] .tree li a:hover,
.tree li a:hover+ul li a {
  background: #04478942;
  color: white;
  border: 2px solid #072c6bb9;
}

/*Connector styles on hover*/
.tree li a:hover+ul li::after,
.tree li a:hover+ul li::before,
.tree li a:hover+ul::before,
.tree li a:hover+ul ul::before {
  border-color: #94a0b4;
}