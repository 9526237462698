.content {
  background-image: url('./../../../assets/images/login-images/login-image.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  position: relative;
}
.logo{
  width: 100%;
  padding: 30px;
  position: absolute;
  z-index: 10;
}
.layer {
  background: linear-gradient(#000000, #363131);
  opacity: 0.5;
  width: 100%;
  
  height: 100%;
}
