.loading {
  position: fixed;
  z-index: 999;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 0 0.6rem #ddddddb3;
  border-radius: 0.5rem;
  max-height: max-content;
  width: 3rem !important;
  height: 3rem !important;
  padding: 0.9rem !important;
}
