.statisticsCard {

    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 14px;
    height: 10%;
    text-align: center;
    transform: scale3d(1, 1, 1) translateX(0);
    transition: transform .5s ease-in-out;
}

[data-theme='light'] .statisticsCard {
    background-color: white;
    transition: all .5s ease-in;
}

[data-theme='dark'] .statisticsCard {
    background-color: rgb(43, 54, 58);
    transition: all .5s ease-out;
}

.statisticsCard:hover {
    transform: translateY(-7px) scale3d(1.01, 1.01, 1.01);
    color: #CB5325;
}

.statisticsCard h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.statisticsCard p {
    font-size: 18px;
    margin-bottom: 5px;
}

.statisticsCard .statisticNumber {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 1px;
}

.statisticsCard .statisticLabel {
    font-size: 16px;
    color: #999;
}

.cursor {
    cursor: pointer;
}