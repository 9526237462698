.oldImageContainer {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;
  width: fit-content;
}

.p0 {
  padding: 0;
}

.imageIcon {
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 0.5rem !important;
  overflow: hidden !important;
  object-fit: contain;
}
