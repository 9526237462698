.container {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 0.5rem;
    height: 100%;
    overflow-y: auto;
  }
  
  [data-theme='light'] .container {
    background-color: white;
  }
    
  [data-theme='dark'] .container {
    background-color: rgb(43, 54, 58);
  }

  .divider {
    margin: 0;
    margin-bottom: 10px;
  }
  
  .formFooter {
    display: flex;
    gap: 0.7rem;
    justify-content: flex-end;
  }
  .button{
    width: min-content;
    margin: 0 0 10px 0;
  }