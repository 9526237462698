@import '../../../../styles/globals.scss';

.footer {
  text-align: center;
  // padding: 0 10px 2px 24px;
  padding-top: 4px;
}

.autoZoneLogo {
  background: linear-gradient(to right, #4cc4d2, #a458a2);
  -webkit-text-fill-color: transparent;
  background-clip: inherit;
  -webkit-background-clip: text;
  font-weight: bold;
}
