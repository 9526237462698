.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 0.5rem;
  height: 100%;
  overflow-y: auto;
  justify-content: space-between;
}

.containerHeader {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 0.5rem;
  height: 10%;
  justify-content: space-between;
  margin-bottom: 1%;
}

[data-theme='light'] .container {
  background-color: white;
}

[data-theme='light'] .containerHeader {
  background-color: white;
}

[data-theme='dark'] .containerHeader {
  background-color: rgb(43, 54, 58);
}

[data-theme='dark'] .container {
  background-color: rgb(43, 54, 58);
}

.divider {
  margin: 0;
  margin-bottom: 10px;
}

.formFooter {
  display: flex;
  gap: 0.7rem;
  justify-content: flex-end;
}

.button {
  width: min-content;
  margin: 0 0 10px 0;
}

[data-theme='dark'] .header {
  border-radius: 10px !important;
  padding-right: 16px;
  color: white !important;
  padding-left: 16px;
}

[data-theme='light'] .header {
  border-radius: 10px !important;
  background: green($color: #000000) !important;
  padding-right: 16px;
  padding-left: 16px;

}

[data-theme='dark'] .headerTop {
  border-radius: 10px !important;
  padding-right: 16px;
  color: white !important;
  padding-left: 16px;
}

[data-theme='light'] .headerTop {
  border-radius: 10px !important;
  background: green($color: #000000) !important;
  padding-right: 16px;
  padding-left: 16px;
}

[data-theme='light'] .title {
  color: black;
}

[data-theme='dark'] .title {
  color: white;
}

.button {
  width: min-content;
  margin-bottom: 10px;
  margin-right: 10px;
}

[data-theme='light'] .title {
  color: black;
}

[data-theme='dark'] .title {
  color: white;
}



.containerImage {
  margin: 10px 10px;
  display: inline;
}