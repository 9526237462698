.header {
  font-weight: bold;
  font-size: 24;
  font-family: 'Cairo';
}

[data-theme='light'] .content {
  background-color: white;
  width: 100%;
  padding: 1rem 1rem 3rem 1rem;
}

[data-theme='dark'] .content {
  background-color: #1e2528;
  width: 100%;
  padding: 1rem 1rem 3rem 1rem;
}

[data-theme='dark'] .link {
  color: #f5f5f5;
}

[data-theme='light'] .link {
  color: #1e2528;
}
@media screen and (max-width: 1020px) {
  .title {
    text-align: center;
  }
  .forgetPass {
    font-size: 0.3rem;
  }
  .checkbox{
    font-size: 10px;
  }
}
.button {
  display: flex;
  justify-content: center;
}
.forgetPass {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  text-decoration: underline;
  margin-top: 5px;
}

[data-theme='light'] .checkbox {
  color: #1e2528;
  margin-block: 10px;
}

[data-theme='dark'] .checkbox {
  color: white;
  margin-block: 10px;
}
