@font-face {
  font-family: 'Cairo';
  src: local('Cairo'),
    url('./assets/fonts/Cairo-Regular.ttf') format('truetype');
}

::selection {
  background-color: #eb5e28;
  color: white;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #242d30 !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}



[data-theme='dark'] .ql-snow .ql-fill {
  fill: white !important;
}

[data-theme='light'] .ql-snow .ql-fill {
  fill: black !important;
}

[data-theme='light'] .ql-snow .ql-stroke.ql-fill {
  color: black !important;
}

[data-theme='dark'] .ql-snow .ql-stroke.ql-fill {
  color: white !important;
}




[data-theme='light'] .ql-snow .ql-stroke {
  stroke: black !important;
}

[data-theme='dark'] .ql-snow .ql-stroke {
  stroke: white !important;
}


[data-theme='light'] .ql-snow .ql-picker-options {
  background-color: #F5F5F5 !important;
}

[data-theme='dark'] .ql-snow .ql-picker-options {
  background-color: #1E2528 !important;
}

[data-theme='light'] .ql-snow .ql-picker {
  color: black !important;
}

[data-theme='dark'] .ql-snow .ql-picker {
  color: white !important;
}

.ql-editor ol,
.ql-editor ul {
  padding-right: 1.5em;
}

.ql-editor li.ql-direction-rtl::before {
  margin-right: -1.5em;
}