$primary-z-index: 1000;
$secondary-z-index: 999;
$basic-z-index: 998;
$primary-color: #eb5f28be;
$secondry-color: #ff8b3eb3;
$link-color: #ff8c3e;

//colors
$dark-bg-1: #404a4d;
$dark-bg-2: #1e2528;
$dark-bg-3: #2b363a;
$dark-bg-4: #222222;

// Text
$text-color: #f1f1f1;

$background-color: $dark-bg-4;
$component-background: $dark-bg-3;
$border-radius-base: 0.42em;

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Cairo';
  font-size: 16px;
  // -webkit-user-select: none;
  // /* Safari */
  // -ms-user-select: none;
  // /* IE 10 and IE 11 */
  // user-select: none;
  // /* Standard syntax */
}

[data-theme='dark'] body {
  color: white;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input {
  border: 0;
  outline: 0;
}

input:focus {
  outline: none !important;
}

/* Change the white to any color */
[data-theme='dark'] input:-webkit-autofill,
[data-theme='dark'] input:-webkit-autofill:hover,
[data-theme='dark'] input:-webkit-autofill:focus,
[data-theme='dark'] input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #3c4c51 inset !important;
  -webkit-text-fill-color: white !important;
}

[data-theme='light'] input:-webkit-autofill,
[data-theme='light'] input:-webkit-autofill:hover,
[data-theme='light'] input:-webkit-autofill:focus,
[data-theme='light'] input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: black !important;
}

/* width */

/* width */
::-webkit-scrollbar {
  width: 5px !important;

}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
[data-theme='dark'] ::-webkit-scrollbar-thumb {
  background-color: $dark-bg-1;
  width: 5px !important;
  transition: background-color 0.2s ease-in-out;
  border-radius: 0.5rem;
}

[data-theme='light'] ::-webkit-scrollbar-thumb {
  background-color: rgb(44, 58, 71, 0.3);
  transition: background-color 0.2s ease-in-out;
  border-radius: 0.5rem;
  width: 5px !important;
}

[data-theme='dark'] .ant-page-header .ant-page-header-heading-title {
  color: white !important;
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: $primary-color;
}