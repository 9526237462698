.fileCardContainer {
    width: fit-content;
    height: fit-content;
    border-radius: 7px;

}

[data-theme='light'] .fileCardContainer {
    background-color: #FAFAFA;
    color: black;
}

[data-theme='dark'] .fileCardContainer {
    background-color: #3F4C52;
    color: white;
}

.imgFile {
    width: 6rem !important;
    height: 6rem !important;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
}

.trash {
    position: absolute;
    margin-inline-start: 20%;
    margin-top: -7px;
    color: red;
    transition-duration: .3s;

}

.trash:hover {
    transform: scale(2);
    transition: all;
    // height: 1rem;
    transition-duration: .3s;
}