@import '../../../../styles/globals.scss';

[data-theme='dark'] .header {
  display: flex;
  padding: 0;
  position: static;
  background-color: #242d30;
  box-shadow: 0 4px 23px 0 rgba($color: $dark-bg-4, $alpha: 0.4);
}

[data-theme='light'] .header {
  display: flex;
  align-items: center;
  padding: 0;
  position: static;
  background-color: white;
  // box-shadow: 0 4px 23px 0 rgba($color: $dark-bg-4, $alpha: 0.4);
}

// .header {
//   display: flex;
//   padding: 0 !important;
//   position: static;
// }

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 0.5rem 0 1rem;
}

.actionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center {
  margin: 0;
  padding: 0;
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.icon {
  display: flex;
  align-items: center;
  margin-inline-end: 1rem;
  cursor: pointer;
}

.navBar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: $primary-z-index;
  padding: 0;
}

.innerContainer {
  width: 100vw;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline-end: 1rem;
}

.avatarContainer {
  height: 4rem;
  display: flex;
  align-items: center;
  margin: 0 1rem;
  transition: 0.3s;
}

.avatarContainer:hover,
.avatarContainer:active {
  cursor: pointer;
}

.userName {
  margin-inline-end: 0.5rem;
  line-height: 1rem;
  text-align: end;
}

.mx1 {
  margin: 0 1rem;
}

.mStart1 {
  margin-inline-start: 0.5rem;
}

.textCenter {
  text-align: center;
}

.mTop1 {
  margin-top: 1rem;
}

.cursorPointer {
  cursor: pointer;
}


[data-theme='dark'] .cursorPointer:hover {
  border-radius: 10px;
  width: 110%;
  background-color: rgba(59, 59, 59, 0.596);
  transition: background-color 0.3s ease-in-out;
}

[data-theme='light'] .cursorPointer:hover {
  border-radius: 10px;
  width: 100%;
  background-color: rgba(179, 191, 197, 0.596);
  transition: background-color 0.3s ease-in-out;
}










.themeSwitcher {
  margin-inline-end: 1rem;
}

.verticalMiddle {
  margin-inline-end: 1rem;
}

// 
.logo {
  float: left;
  height: 64px;
  padding: 0.3rem 0;
}

:global(.rtl) {
  .logo {
    float: right;
  }
}

.logo img {
  height: 100%;
  width: 100%;
}

.header {
  position: fixed;
  z-index: 3;
  width: 100%;
  padding-inline-start: 10px !important;
  z-index: 10;
}

.menu {
  justify-content: flex-end;
}

.auth-user-settings {
  min-width: 350px;
  margin: 0 !important;
  height: auto !important;
  background-color: #fff;
  cursor: auto !important;
}

.title {
  font-weight: bold;
  font-size: small;
}

.body {
  font-weight: 200;
  font-size: smaller;
}


.billIcon {
  font-size: large;
}