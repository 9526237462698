[data-theme='light'] .container {
  background-color: white;
  border-radius: 1rem;


}

[data-theme='dark'] .container {
  background-color: rgb(43, 54, 58);
  border-radius: 1rem;


}

.card {
  display: flex;
  justify-content: space-between;
  min-width: 50px;


}

.removeAllStyle {
  all: unset !important;
}

.divider {
  margin: 5px 0 13px 0;
  padding: 0;
}

.center {
  text-align: center;
}

.icon {
  width: 32px;
  height: 32px;
  object-fit: fill;
}

.span {
  all: unset !important;
  font-size: 1.2rem !important;


}

.visitedNumber {
  transform: translateY(12px) translateX(12px) scale(1.3);
}

.test :hover {
  background-color: red;
}


.drowpDownList {
  cursor: pointer,
}