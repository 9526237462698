.img {
    border-radius: 50% !important;
    width: 6rem !important;
    object-fit: fill !important;
    height: 6rem !important;
}



.buttonEdite {
    margin: .8rem 0 10px 0;
    width: 7rem !important;
    height: fit-content;
    border-radius: 5px !important;
    padding: 2px 5px 2px 5px !important;
    font-size: small;

}

.buttonDelete {
    margin: 3px 0 0 0;
    width: 7rem !important;
    height: fit-content;
    border-radius: 5px !important;
    padding: 2px 5px 2px 5px !important;
    font-size: small;

}




.delete {
    width: 15px;
    position: absolute;
    z-index: 10;
    bottom: 1px;
}

.colImg {
    display: flex;
    justify-content: center;
    align-items: center;
}


.img {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
}

.icon {
    font-size: 12px;
}