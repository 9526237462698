.img {
    border-radius: 50% !important;
    width: 6rem !important;
    object-fit: fill !important;
    height: 6rem !important;
}



.buttonEdite {
    margin: .8rem 0 10px 0;
    width: 7rem !important;
    height: fit-content;
    border-radius: 5px !important;
    padding: 2px 5px 2px 5px !important;
    font-size: small;

}

.buttonDelete {
    margin: 3px 0 0 0;
    width: 7rem !important;
    height: fit-content;
    border-radius: 5px !important;
    padding: 2px 5px 2px 5px !important;
    font-size: small;

}