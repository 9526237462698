.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
  background-image: url('../../assets/images/login-images/background-login.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.content {
  display: flex;
  flex-direction: row;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0px 3px 3px  #6C6C6C;
  background: #fff;
  min-height: auto;
  min-width: 55vw;
  position: absolute;

}
.layer {
  background-color: #5b3920;
  opacity: 0.4;
  width: 100vw;
  position: absolute;
  height: 100vh;
}
@media screen and (max-width: 1020px) {
  .content{
    flex-direction: column;
  }
}