@import '../../styles/globals.scss';


[data-theme='dark'] .container {
  background: $dark-bg-3;
  border-radius: $border-radius-base;
  overflow: hidden;
}

[data-theme='light'] .container {
  background: white;
  border-radius: $border-radius-base;
  overflow: hidden;
}

.imageIcon {
  height: 3rem;
  width: 3rem;
  margin: auto;
  border-radius: 0.24em;
}

.pagination{
  display: flex !important;
  justify-content: center !important;
}