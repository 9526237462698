.message {}

[data-theme='light'] .message {
    color: black;
    font-size: 1.2rem;
    text-align: center;
}

[data-theme='dark'] .message {
    color: white;
    font-size: 1.2rem;
    text-align: center;
}