.fileCardContainer {
  width: 80%;
  height: 4rem;
  border-radius: 7px;

}

[data-theme='light'] .fileCardContainer {
  background-color: #FAFAFA;
  color: black;
}

[data-theme='dark'] .fileCardContainer {
  background-color: #3F4C52;
  color: white;
}