.imgFile {
  width: 2rem !important;
  height: 2rem !important;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}
.fileCardContainer {
  width: fit-content;
  height: fit-content;
  border-radius: 7px;
  align-self: center;
}

[data-theme="light"] .fileCardContainer {
  background-color: #fafafa;
  color: black;
}

[data-theme="dark"] .fileCardContainer {
  background-color: #3f4c52;
  color: white;
}
