@import '/src/styles/globals.scss';


[data-theme='dark'] .header {
  border-radius: 10px !important;
  padding-right: 16px;
  color: white !important;
  padding-left: 16px;
}

[data-theme='light'] .header {
  border-radius: 10px !important;
  background: green($color: #000000) !important;
  padding-right: 16px;
  padding-left: 16px;
}

[data-theme='light'] .title {
  color: black;
}

[data-theme='dark'] .title {
  color: white;
}