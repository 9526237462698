.layoutOuterContent {
  padding: 0;
  margin: 0;
  // min-height: 280;
  max-height: 100vh;
}

.layoutInnerContent {
  height: calc(100vh - 94px);
  padding: 1.5rem;
  overflow: auto;
}
