.columns {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 1rem;
    padding: 3px;
    width: 100%;
}

.hiden {
    visibility: hidden;
    display: none;
}