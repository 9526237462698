@import '../../../../styles/globals.scss';

// [data-theme='dark'] .sider {
//   z-index: $secondary-z-index;
//   overflow: hidden;
//   max-height: calc(100vh - 3px);
//   background-color: red;
// }

// [data-theme='light'] .sider {
//   z-index: $secondary-z-index;
//   overflow: hidden;
//   max-height: calc(100vh - 3px);
//   background-color: red;
// }

.sider {
  max-height: 100vh;
  overflow: hidden;
}

[data-theme='dark'] .sider {
  background-color: #2b363a;
}

// .collapsedMenu::-webkit-scrollbar {
//   display: none !important;
// }

.siderLeft {
  left: 0;
}

.siderRight {
  right: 0;
}

[data-theme='dark'] .menu {
  background-color: #2b363a;
}

.menu {
  overflow: auto;
  height: 100%;
  border: none !important;

  padding: 0 0 4rem 0;
}

.menuItem {
  margin-top: 0px;
}

.brand {
  display: flex;
  justify-content: space-between;
  height: 2.5rem;
  margin: 1rem;
}

.collapsedBrand {
  display: block;
  text-align: center;
  height: 2rem;
  margin: 1rem;
}

.brandImage {
  height: 100%;
  object-fit: contain;
  cursor: pointer;
  margin: auto;
}

.brandName {
  text-align: start;
  flex-grow: 1;
  align-items: center;
  display: flex;
  padding-inline-start: 0.5rem;
  font-size: large;
  font-weight: bold;
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.triggerContainer {
  display: flex;
  align-items: center;
  animation: fadeIn 2s;
}
.hide {
  display: none;
}

.trigger {
  font-size: 1.2rem;
  margin: auto;
}
